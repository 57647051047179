<template>
  <div class="simple-accordion" :id="this.id" v-bind="initData">
    <div class="simple-accordion-header pointer" @click="activeAccordion">
      <h3 class="simple-accordion-header-title title">
        <i class="fa fa-question-circle icon"></i>
        {{ title }}
      </h3>
      <div class="simple-accordion-options">
        <a class="button"><i class="fa fa-chevron-down"></i></a>
      </div>
    </div>
    <div class="simple-accordion-content content" v-html="content"></div>
  </div>
</template>

<script>
  import global from '@/assets/js/global.js';
  export default {
    data: () => {
      return {
        id: null,
      };
    },
    props: {
      title: String,
      content: String,
    },
    methods: {
      activeAccordion: function () {
        let element = document.getElementById(this.id);
        element ? element.classList.toggle('active') : false;
      },
      idAccordion: function () {
        this.id = global.fastID();
      },
    },
    computed: {
      initData: function () {
        this.idAccordion();
      },
    },
  };
</script>

<style lang="scss">
  @import '@/assets/styles/vars.scss';
  $textColor: #5b5957;
  .simple-accordion {
    &-header {
      display: flex;
      justify-content: space-between;
      padding: $mpadding;
      &:active {
        background-color: $pattens_blue;
      }
      .icon {
        margin-right: 5px;
      }
      &-title {
        font-size: 16px;
        font-weight: normal;
      }
    }
    &:not(:last-child) &-header {
      border-bottom: solid 1px $alto;
    }
    &-options {
      .button {
        display: inline-block;
        transition: 0.3s ease-in-out;
        color: $chicago;
      }
    }
    &-content {
      display: none;
      overflow: hidden;
      padding: $mpadding $mpadding * 2;
      margin-left: 8px;
      > *{
        padding: $mpadding/2 0;
        &:first-child{
          padding-top: 0;
        }
        &:last-child{
          padding-bottom: 0;
        }
      }
    }
    &.active {
      .title,
      .button {
        color: $primary_color;
        font-weight: bold;
      }
      .button {
        transform: rotate(180deg);
      }
      .content {
        display: block;
      }
    }
    &:not(:last-child) {
      .content {
        border-bottom: solid 1px $alto;
      }
    }
    &:last-child {
      .content {
        border-top: solid 1px $alto;
      }
    }
  }
</style>
