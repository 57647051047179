<template>
  <section v-bind="initData">
    <div class="section-contact section" id="contact" v-if="data && lang">
      <div class="section-contact-wrap">
        <div class="section-contact-header">
          <div class="section-contact-header-wrap main">
            <div class="section-contact-header-main" :style="`background-image: url(${data.bannerLink})`"></div>
          </div>
        </div>
        <div class="section-contact-list">
          <div class="section-contact-list-wrap main">
            <div class="section-contact-list-main">
              <div class="section-contact-list-aside">
                <h3 class="section-contact-list-head">
                  {{ locale.contactTitle }}
                </h3>
                <div class="section-contact-list-aside-content">
                  <div class="section-contact-list-aside-content-wrap">
                    <div class="section-contact-list-aside-content-line" v-for="(contact, index) in locale.contact_us" :key="index">
                      <img class="icon" :src="contact.icon" :alt="contact.network" />
                      <div class="section-contact-list-aside-content-line-txt">
                        <a target="_blank" rel="noopener" :href="typeContact(contact)" v-if="typeContact(contact)">
                          {{ contact.name }}
                        </a>
                        <span v-else>{{ contact.name }}</span>
                      </div>
                    </div>
                    <div class="section-contact-list-aside-content-line">
                      <i class="fa fa-clock-o"></i>
                      <div class="section-contact-list-aside-content-line-txt">
                        <a>
                          <p>
                            {{ locale.schedule.message }}
                          </p>
                          <p>
                            {{ locale.schedule.weekdays }}
                          </p>
                          <p>
                            {{ locale.schedule.weekends }}
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="section-contact-list-aside">
                <h3 class="section-contact-list-head">
                  {{ locale.social_media_title }}
                </h3>
                <div class="section-contact-list-aside-content">
                  <div class="section-contact-list-aside-content-wrap">
                    <a :href="account.url || '#'" target="_blank" class="section-contact-list-aside-content-line" v-for="(account, index) in locale.social_media" :key="index">
                      <iconic class="icon" :svgCode="account.icon" />
                      <div class="section-contact-list-aside-content-line-txt" :class="account.network === 'skype' ? 'section-contact-skype' : ''">
                        <a target="_blank" :href="account.url" v-if="account.url">
                          {{ account.name }}
                        </a>
                        <span class="section-contact-list-aside-content-line-txt-name" v-else>{{ account.name }}</span>
                        <span class="section-contact-list-aside-content-line-txt-badge">
                          {{ locale.account_veracity }}
                        </span>
                        <div class="section-contact-skype-error" v-if="account.network === 'skype'">
                          {{ account.descript }}
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="section-contact-list-aside">
                <h3 class="section-contact-list-head">
                  {{ locale.warningsTitle }}
                </h3>
                <div class="section-contact-list-aside-content">
                  <div class="section-contact-list-aside-content-wrap">
                    <div class="section-contact-list-aside-content-line" v-for="(warning, index) in locale.warnings" :key="index">
                      <i class="fa fa-exclamation-triangle"></i>
                      <div class="section-contact-list-aside-content-line-txt">
                        <div class="section-contact-list-aside-content-line-txt-important">
                          {{ warning }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import store from "../../store/index.js";
  import SingleAccordion from "./SingleAccordion.vue";

  export default {
    components: { SingleAccordion },
    data: () => {
      return {
        contact: "",
        lang: null,
        locale: null,
        language: "es",
      };
    },
    metaInfo: function () {
      const title = this.locale ? "- " + this.locale.contact_us_title : "";
      return {
        title: `Models1A ${title}`,
      };
    },
    updated() {
      this.language = localStorage.getItem("user-language");
    },

    computed: {
      data() {
        const banners = {
          mobile: () => this.urlCloudFront("https://new-web-site-bucket.s3.amazonaws.com/blogResponsiveAcualizacion/banner-contacto-2024-mobile.webp"),
          tablet: () => this.urlCloudFront(`https://new-web-site-bucket.s3.amazonaws.com/blogResponsiveAcualizacion/banner-contacto-2024-tablet${this.language === "en" ? "-ingles" : ""}.webp`),
          desktop: () => this.urlCloudFront(`https://new-web-site-bucket.s3.amazonaws.com/blogResponsiveAcualizacion/banner-contacto-2024-escritorio${this.language === "en" ? "-ingles" : ""}.webp`),
        };

        const device = this.getDevice();
        const data = {
          bannerLink: banners?.[device]?.(),
          title: "Contacto",
          subtitle: "Canales de atención",
        };
        return data;
      },
      initData: async function () {
        await this.getLang();
      },
    },
    methods: {
      getDevice() {
        if (this.$screen.width <= 767) {
          return "mobile";
        } else if (this.$screen.width < 1024) {
          return "tablet";
        } else {
          return "desktop";
        }
      },
      getLang: async function () {
        if (store.getters["iflanguage"]) {
          let dataLang = await store.getters["dataLang"];
          this.locale = {
            ...dataLang.contactInfo,
            social_media: dataLang.resource.social_media,
            contact_us: dataLang.resource.contact_us,
            warnings: dataLang.resource.warnings,
          };
          this.lang = dataLang.footer;
        }
      },
      urlCloudFront(link) {
        return this.$global.newUrl(link);
      },
      typeContact: function (contact) {
        if (contact.network === "Email") {
          return `mailto:${contact.name}`;
        }
        return contact.url ? contact.url : false;
      },
    },
  };
</script>

<style lang="scss">
  @import "@/assets/styles/vars.scss";

  .section-contact {
    text-align: center;
    margin-top: 70px;
    &-header {
      height: auto;
      @include Flex(column, center, center);
      &-wrap {
        display: flex;
        justify-content: center;
        height: 100%;
        width: 100%;
        margin: 200px;
      }
      &-main {
        background-color: $white_smoke;
        height: 370px;
        width: 98%;
        margin: 0 auto;
        border-radius: $mradius;
        background-size: cover;
        background-position: 50% 50%;
        box-shadow: $dshadow;
        background-repeat: no-repeat;
      }
    }

    &-list {
      &-main {
        display: block;
        padding: 0 $mpadding $mpadding $mpadding;
        text-align: left;
      }
      &-aside {
        &-content {
          padding: $mpadding;
          &-line {
            display: flex;

            .icon {
              width: 16px;
              height: 16px;
            }
            &-txt {
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin-left: $mpadding;

              &-base-url {
                display: none;
              }

              &-important {
                font-style: italic;
                opacity: 0.6;
              }

              &-badge {
                font-weight: bold;
                padding: 4px 8px;
                font-size: 13px;
                border-radius: 4px;
                color: white;
                background: $primary_color;
                text-transform: capitalize;
              }

              a {
                overflow: hidden;
                text-overflow: ellipsis;
              }

              a[href]:hover {
                color: $primary_color;
                text-decoration: underline;
              }

              &-badge:hover {
                text-decoration: none;
                text-decoration-color: #515151;
              }

              p {
                margin-bottom: $mpadding/2;
              }
            }

            i {
              width: 16px;
            }

            &:not(:last-child) &-txt {
              padding-bottom: $mpadding;
              margin-bottom: $mpadding;
              border-bottom: solid 1px $alto;
            }
          }
        }
      }
      &-content {
        width: 100%;
      }
      &-aside,
      &-content {
        width: 100%;
        box-shadow: $dshadow;
        border-radius: $mradius;
        margin-top: 20px;
      }

      &-head {
        padding: $mpadding/2 $mpadding;
        color: $white;
        font: {
          weight: normal;
          size: 120%;
          family: $sec_font;
        }
        background-color: $primary_color;
        border-radius: $mradius/2 $mradius/2 0 0;
      }
    }
    &-skype {
      display: grid;
      grid-template-columns: 150px 58px;
      &-error {
        min-width: 65vw;
        padding-top: 3px;
        font-style: italic;
        opacity: 0.6;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    @media (min-width: $mobile_width) {
      &-list-aside-content-line-txt {
        &-name,
        a {
          min-width: 280px;
        }
      }
      &-header {
        height: 450px;
        &-wrap {
          height: 100%;
          width: 100%;
          margin: 200px;
          display: flex;
        }
        &-main {
          height: 100%;
          background-position: 50% 15%;
        }
      }
    }
    @media (min-width: $tablet_width) {
      &-skype {
        display: flex;
        &-error {
          font-size: 16px;
          min-width: auto;
          margin-left: 15px;
        }
      }
      &-header {
        height: 200px;
        margin-top: 100px;
        &-wrap {
          height: 200px;
        }
        &-main {
          height: 100%;
          background-size: cover;
        }
      }
      &-list {
        &-main {
          display: block;
          text-align: left;
        }
        &-aside {
          &-content {
            padding: $mpadding;
            &-line {
              display: flex;

              .icon {
                width: 16px;
                height: 16px;
              }
              &-txt {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-left: $mpadding;

                &-base-url {
                  display: none;
                }

                &-important {
                  font-style: italic;
                  opacity: 0.6;
                }

                &-badge {
                  font-weight: bold;
                  padding: 4px 8px;
                  font-size: 13px;
                  border-radius: 4px;
                  color: white;
                  background: $primary_color;
                  text-transform: capitalize;
                }

                a {
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                a[href]:hover {
                  color: $primary_color;
                  text-decoration: underline;
                }

                &-badge:hover {
                  text-decoration: none;
                  text-decoration-color: #515151;
                }

                p {
                  margin-bottom: $mpadding/2;
                }
              }

              i {
                width: 16px;
              }

              &:not(:last-child) &-txt {
                padding-bottom: $mpadding;
                margin-bottom: $mpadding;
                border-bottom: solid 1px $alto;
              }
            }
          }
        }
        &-content {
          width: 100%;
        }
        &-aside,
        &-content {
          width: 100%;
          box-shadow: $dshadow;
          border-radius: $mradius;
          margin-top: 20px;
        }

        &-head {
          padding: $mpadding/2 $mpadding;
          color: $white;
          font: {
            weight: normal;
            size: 120%;
            family: $sec_font;
          }
          background-color: $primary_color;
          border-radius: $mradius/2 $mradius/2 0 0;
        }
      }
      &-skype {
        display: grid;
        grid-template-columns: 150px 58px;
        &-error {
          min-width: 65vw;
          padding-top: 3px;
          font-style: italic;
          opacity: 0.6;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    @media screen and (min-width: 1024px) {
      &-header {
        &-main {
          height: 100%;
          background-size: contain;
        }
      }
    }
  }
</style>
